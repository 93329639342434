.Home {
    background-color: #282c34;
    overflow-y: auto;
    height: 100vh;
    scroll-snap-type: y mandatory;
    animation: openIn 1s;
}

.SectionHeader {
    background-color: inherit;
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.SectionHeaderText {
    align-content: flex-start;
}

.SectionYear {
    height: 100vh;
    color: black;
    background-color: green;
    padding-top: 2em;
}

.snap {
    scroll-snap-align: start;
}

.SectionIntro {
    position: static;
    top: 0;
    background-color: #282c34;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    min-height: 100vh;
}

.SectionIntroCompanyInfo {
    text-align: right;
    margin-top: auto;
    margin-left: auto;
    margin-right: 10px;
    margin-bottom: 10px;
}

.SectionIntroSkillInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.SectionIntroSkillInfoSocial {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.SectionIntroSkillInfoSocialIcon {
    width: 2em;
    height: 2em;
    margin-right: 1em;
    cursor: pointer;
}

a {
    color: inherit;
    text-decoration: inherit;
}

.SectionIntroSkillInfoGooglePlay {
    height: 4em;
    width: auto;
}

@keyframes openIn {
    from {
        opacity: 0;
        transform: rotateX(-10deg);
    }

    to {
        opacity: 1;
        transform: rotateX(0deg)
    }
}