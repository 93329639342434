@font-face {
  font-family: "RobotoMono";
  src: local("RobotoMono"),
    url("./fonts/robotomono.ttf") format("truetype");
  font-weight: normal;
}

body {
  margin: 0;
  font-family: "RobotoMono";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}